<template>
    <div class="admin-content">
        <h3>지원자 관리</h3>
        <div class="flex-wrap right">
            <div class="ml10">
                <div class="btn-wrap">
                    <button class="btn-box blue" @click="methods.getApplicant()">조회</button>
                </div>
            </div>
            <div class="sch-wrap3">
                <div>
                    <label>채용공고</label>
                    <div class="select-box" style="width:300px;">
                        <select v-model="state.selectedNotice" @change="methods.getPart()">
                            <option v-for="notice in state.dataTable.recruitNotice" v-bind:value="notice"
                                v-bind:key="notice.Seq" v-text="notice.Title"></option>
                        </select>
                    </div>
                </div>
                <div>
                    <label>지원분야</label>
                    <div class="select-box">
                        <select v-model="state.selectedPart">
                            <option value="">전체</option>
                            <option v-for="part in state.dataTable.recruitPart" v-bind:value="part.APCT_PT_CD"
                                v-bind:key="part" v-text="part.APCT_PT_NM"></option>
                        </select>
                    </div>
                </div>
                <div>
                    <label>지원자명</label>
                    <div>
                        <input type="text" class="input-text" v-model="state.parameter.APCTP_KR_NM" style="width:180px;"/>
                    </div>
                </div>
                <div class="chk-box" v-if="state.author.rc">
                    <input type="checkbox" v-model="state.dataTable.memoYn" />
                    <label>
                        <p>메모 있음</p>
                    </label>
                </div>
                <div class="ml10">
                    <button type="button" class="btn-box orange" @click="eventHandler.onRexpertClick()">입사지원서</button>
                </div>
            </div>
        </div>
        <div class="total-box flex-wrap align-center">
            <p>Total <strong>{{ state.dataTable.applicantInfo.length }}</strong></p>
            <button @click="eventHandler.selectAllOnCurrentPage" class="btn-box blue btn-sm ml-2">전체 선택/해제</button>
        </div>

        <div class="table-wrap no-all-check">
            <DataTable
                class="p-datatable-sm"
                scrollable
                paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                columnResizeMode="fit"
                :rowHover="true"
                :resizable-columns="true"
                scrollHeight="470px"
                :paginator="true"
                :rows="100"
                :value="state.dataTable.applicantInfo"
                v-model:selection="state.selectedApplicant"
                :select-all="false"
                @page="eventHandler.onPageChange">
                <template #empty>
                    데이터가 없습니다.
                </template>
                
                <Column selectionMode="multiple" style="flex: 0 0 40px" class="tc"/>
                <Column field="APCTP_NO" header="지원자번호" />
                <Column field="EXAM_NO_orderby" header="수험번호" :sortable="true" />
                <Column field="APCTP_KR_NM" header="성명" :sortable="true" >
                    <template #body="{ data }">
                        <div @click="eventHandler.onApplicantClick(data)" class="a-hover">
                            <a href='javascript:void(0);'>{{ data.APCTP_KR_NM }}</a>
                        </div>
                    </template>
                </Column>
                <Column field="APCT_PT_NM" header="지원분야" :sortable="true"/>
                <Column field="EXAM_DT" header="최종제출일시" :sortable="true" bodyStyle="text-align:center"/>
                <Column v-if="state.author.rc" field="MEMO_REG_YN" header="메모" :sortable="true">
                    <template #body="{ data }">
                        <div class="flex-wrap center" @click="eventHandler.onMemoClick(data.EXAM_NO)">
                            <button type="button" v-if="data.MEMO_REG_YN == '보기'" class="btn-box green">{{ data.MEMO_REG_YN }}</button>
                            <button type="button" v-if="data.MEMO_REG_YN == '등록'" class="btn-box gray">미등록</button>
                        </div>
                    </template>
                </Column>
                <Column v-if="state.author.adm" field="USR_NM" header="평가자"/>
            </DataTable>
        </div>
        <Dialog
            :header="state.dialog.header"
            :visible="state.dialog.isOpen"
            @show="eventHandler.dialog.show"
            @hide="eventHandler.dialog.hide"
            @close="state.dialog.isOpen = false"
            @update:visible="state.dialog.isOpen = false">

            <div class="dialog-wrap1">
                <Textarea v-model="state.dataTable.memoContent.MEMO_CONT" class="dialog-wrap1" :auto-resize="true" rows="10" cols="50">
                </Textarea>
            </div>
            <br>
            <div class="flex-wrap right">
                <div class="ml10">
                    <div class="btn-wrap">
                        <button v-show="state.dialog.isNew" class="btn-box gray" @click="methods.saveMemoCont(state.dataTable.memoContent)">저장</button>
                        <button v-show="!state.dialog.isNew" class="btn-box gray" @click="methods.saveMemoCont(state.dataTable.memoContent)">수정</button>
                        <button v-show="!state.dialog.isNew" :visible="!state.dialog.isNew" class="btn-box red" @click="methods.deleteMemoCont(state.dataTable.memoContent)">삭제</button>
                    </div>
                </div>
            </div>
        </Dialog>

        <Dialog
            :header="state.dialogPreview.header"
            :visible="state.dialogPreview.isOpen"
            :modal="true"
            @show="eventHandler.dialogPreview.show"
            @hide="eventHandler.dialogPreview.hide"
            @close="state.dialogPreview.isOpen = false"
            @update:visible="state.dialogPreview.isOpen = false">

            <div style="width: 1500px;">
                <RECRC0310D :Seq="state.applicant.Seq" :FORM_NO="state.applicant.FORM_NO" :APCT_SEQ="state.applicant.APCT_SEQ"></RECRC0310D>
            </div>
        </Dialog>
    </div>
</template>

<script>
import { reactive } from '@vue/reactivity';
import { onMounted } from '@vue/runtime-core';
import { useRoute } from "vue-router";
import { useStore } from 'vuex';
import { applicantMng } from '@/modules/recruit/REC_RC0300R.js';
import alertManager from '@/utils/alert-confirm-manager';
import RECRC0310D from '@/router/views/recruit/REC_RC0310D.vue';
import router from '@/router';

export default {
    components: { RECRC0310D },
    setup() {
        // 라우트
        const vrt = useRoute();

        // 스토어
        const store = useStore();

        // 로그인된 아이디 정보
        const _userInfo = JSON.parse(sessionStorage.getItem('UserInfo'));

        // state
        const state = reactive({

            parameter: {

                Seq: 0,

                boardCategory: 'RECCM001',

                userInfo: _userInfo.EmplID,

                APCT_PT_CD: '',

                APCTP_KR_NM: '',

                MEMO_REG_YN: ''
            },

            dataTable: {

                // 채용공고 목록
                recruitNotice: new Array(),

                // 지원분야 목록
                recruitPart: new Array(),

                // 지원자 목록
                applicantInfo: new Array(),

                // 평가자/인사팀 구분
                checkAuthor: new Array(),

                // 메모 등록여부
                memoYn: '',

                // 메모 내용
                memoContent: new Object(),
            },

            // 선택된 공고
            selectedNotice: {

                BoardCategory: '',

                ExpiredDt: '',

                Seq: 0,

                StartpiredDt: '',

                SystCode: '',

                Title: ''
            },

            // 선택된 지원분야
            selectedPart: '',

            // 평가자/메모 컬럼 그룹권한에 따라 보이기
            author: {

                // 평가자 권한(RC001) 있으면 true
                rc: false,

                // 관리자 권한(ADM001 또는 ADM003) 있으면 true
                adm: false
            }

            // 지원자 정보
            , applicant:{

                Seq: ''
                , FORM_NO: 0
                , EXAM_NO: 0
                , APCT_SEQ: 0
            }

            // 메모 팝업
            , dialog: {

                header: "평가 메모",

                // 팝업 오픈 여부
                isOpen: false,

                // 메모 신규 여부
                isNew: true
            }

            // 지원자 상세보기 팝업
            , dialogPreview: {

                header: "입사지원서 상세보기",

                // 팝업 오픈 여부
                isOpen: false
            }

            // 선택한 지원서
            , selectedApplicant: new Array()

            // 현재 체크박스 데이터
            , currentPageData: new Array()
            
            // 전체 선택/해제
            , selectAllState: false
        })

        const methods = {

            // 검색조건 공고목록 조회
            getNotice: () => {

                // 스토어 값 변경
                store.commit("setLoadingStatus", true)

                state.selectedApplicant = [] ;

                applicantMng.getNotice(state.parameter.boardCategory, state.parameter.userInfo).then(result => {

                    state.dataTable.recruitNotice = result.Table.rows;
                    if (result.Table.rows.length > 0) {
                        state.selectedNotice = state.dataTable.recruitNotice[0];
                    }

                    // 로그인 아이디 권한그룹 체크
                    let author = result.Table1.rows;

                    var checkRC = author.some(v => v.GrpID === 'RC001')
                    if (checkRC == true) state.author.rc = true

                    var checkADM001 = author.some(v => v.GrpID === 'ADM001')
                    var checkADM003 = author.some(v => v.GrpID === 'ADM003')
                    if (checkADM001 == true) {
                        state.author.adm = true
                    } else if (checkADM003 == true) {
                        state.author.adm = true
                    }

                }).finally(() => {

                    // 스토어 값 변경
                    store.commit("setLoadingStatus", false)

                    methods.getPart();
                })
            },

            // 검색조건 지원분야 조회
            getPart: () => {

                // 스토어 값 변경
                store.commit("setLoadingStatus", true)
                
                state.parameter.Seq = state.selectedNotice.Seq.toString();
                applicantMng.getPart(state.parameter.Seq, state.parameter.userInfo).then(result => {

                    state.dataTable.recruitPart = result.Table.rows;
                    state.selectedPart = '';

                }).finally(() => {

                    // 스토어 값 변경
                    store.commit("setLoadingStatus", false);

                    methods.getApplicant();
                });
            },

            // 지원자 조회
            getApplicant: () => {

                // 스토어 값 변경
                store.commit("setLoadingStatus", true)

                state.parameter.APCT_PT_CD = state.selectedPart;

                // 메모있음 체크시 'Y', 미체크시 'N'
                if (state.dataTable.memoYn === true) state.parameter.MEMO_REG_YN = 'Y'
                else state.parameter.MEMO_REG_YN = 'N'

                // 지원자선택 초기화
                state.selectedApplicant = new Array();
                state.selectAllState = false;

                applicantMng.getApplicant(JSON.stringify(state.parameter)).then(result => {

                    state.dataTable.applicantInfo = result.Table.rows;

                    // 현재 페이지 전체 데이터
                    state.currentPageData = result.Table.rows.slice(0, 100);

                }).finally(() => {

                    // 스토어 값 변경
                    store.commit("setLoadingStatus", false);
                });
            },

            // 메모 내용 조회
            getEvalMemo: (data) => {

                // 스토어 값 변경
                store.commit("setLoadingStatus", true)

                applicantMng.getEvalMemo(data.toString()).then(result => {

                    state.dataTable.memoContent = result.Table.rows[0];
                    if(result.Table.rows[0]["MEMO_REG_YN"] === 'Y') state.dialog.isNew = false;

                }).finally(() => {

                    // 스토어 값 변경
                    store.commit("setLoadingStatus", false);
                });
            },

            // 메모 내용 등록
            saveMemoCont: (data) => {

                if (!data.MEMO_CONT?.trim()) {
                    alertManager.alert.warning('메모를 입력해주세요')
                    return
                }

                // 스토어 값 변경
                store.commit("setLoadingStatus", true)

                applicantMng.saveEvalMemo(JSON.stringify(data)).then((response) => {

                    if(response.data.success) {

                        alertManager.alert.success('저장되었습니다.')

                        methods.getApplicant();
                    }
                }).finally(() => {

                    // 스토어 값 변경
                    store.commit("setLoadingStatus", false);

                    // 팝업 닫기
                    state.dialog.isOpen = false;
                });
            },

            // 메모 내용 삭제
            deleteMemoCont: (data) => {

                alertManager.confirm.remove().then(() => {

                    // 스토어 값 변경
                    store.commit("setLoadingStatus", true)

                    applicantMng.deleteEvalMemo(data.EXAM_NO.toString()).then((response) => {

                        if (response.data.success) {

                            alertManager.alert.success('삭제되었습니다.')

                            methods.getApplicant();
                        }
                    }).finally(() => {

                        // 스토어 값 변경
                        store.commit("setLoadingStatus", false);

                        // 팝업 닫기
                        state.dialog.isOpen = false;

                        // 메모 등록여부 초기화
                        state.dataTable.memoYn = false;
                    });
                });
            }
        }

        const eventHandler = {

            // 지원자 클릭
            onApplicantClick:(data) => {

                state.applicant.Seq = state.parameter.Seq
                state.applicant.FORM_NO = data.FORM_NO.toString()
                //state.applicant.EXAM_NO = data.EXAM_NO
                state.applicant.APCT_SEQ = data.APCT_SEQ

                state.dialogPreview.isOpen = true;
            },

            // 메모 클릭시
            onMemoClick: (data) => {

                methods.getEvalMemo(data);

                state.dialog.isOpen = true;
            },


            // 입사지원서 클릭 이벤트 (Rexpert 뷰어 오픈)
            onRexpertClick: () => {
                if(state.selectedApplicant.length == 0){
                    alertManager.alert.warning('선택된 지원자가 없습니다.');
                    return
                }
                // 파라미터 넘기기
                let apct_seq = '';
                let seq, div_cd;
                state.selectedApplicant.forEach(data =>{
                    apct_seq += data.APCT_SEQ + ',',
                    seq = state.selectedNotice.Seq.toString()
                })

                apct_seq = apct_seq.slice(0, -1);

                state.dataTable.recruitNotice.forEach(data => {
                    if(data.Seq == seq){

                        div_cd = data.CARR_DIV_CD != 'CD021001' ? '경력.crf' : '신입.crf';
                    }
                })
                
                // 결과 페이지 이동 경로 설정
                switch (location.hostname) {
                    // 개발자 로컬
                    case 'localhost':
                        window.open('http://localhost/Koreit.Web.API/ClipReport4/report4.aspx?div_cd='+div_cd+'&APCT_SEQ='+apct_seq+'&SEQ='+seq+'&PAGE=300');
                        break;
                    // 미니소프트 개발 (나중에 주소 수정할것)
                    case 'admin-koreit.minisoft.co.kr':
                        window.open('https://webapi-koreit.minisoft.co.kr/ClipReport4/report4.aspx?div_cd='+div_cd+'&APCT_SEQ='+apct_seq+'&SEQ='+seq+'&PAGE=300');
                        break;
                    // 한토신 신개발
                    case 'devadmin.koreit.co.kr':
                        window.open('http://devwebapi.koreit.co.kr/ClipReport4/report4.aspx?div_cd='+div_cd+'&APCT_SEQ='+apct_seq+'&SEQ='+seq+'&PAGE=300');
                        break;
                    // 한토신 신운영
                    case 'admin.koreit.co.kr':
                        window.open('https://webapi.koreit.co.kr/ClipReport4/report4.aspx?div_cd='+div_cd+'&APCT_SEQ='+apct_seq+'&SEQ='+seq+'&PAGE=300');
                        break;
                    // 미니소프트 개발
                    default:
                        window.open('https://webapi-koreit.minisoft.co.kr/ClipReport4/report4.aspx?div_cd='+div_cd+'&APCT_SEQ='+apct_seq+'&SEQ='+seq+'&PAGE=300');
                        break;
                }
            },

            dialog: {

                show: () => {

                    // 신규 다이얼로그 모드
                    if (state.dialog.isNew) {

                        // 메모 등록여부 값 설정
                        state.dataTable.memoYn = 'Y';

                        // 수정여부
                        state.dataTable.memoContent.updateSave = false;
                    }
                },

                hide: () => {

                    // 행 데이터모델 초기화
                    state.dataTable.memoContent = new Object();
                    state.dialog.isNew = true;

                    // 수정여부
                    state.dataTable.memoContent.updateSave = false;
                }
            }

            , dialogPreview: {

                show: () => {
                    
                },

                hide: () => {

                }
            }

            //페이지 변경시 체크박스 초기화
            , onPageChange: (event) => {

                state.selectedApplicant = new Array();
                state.selectAllState = false;
                const currentPageNumber = event.page + 1; // 현재 페이지 (번호 2일때 1)
                
                const startIndex = (currentPageNumber - 1) * 100;
                const endIndex = startIndex + 100;
                state.currentPageData = state.dataTable.applicantInfo.slice(startIndex, endIndex);
            }

            //현재페이지 체크박스 전체 선택
            , selectAllOnCurrentPage: () => {

                if (state.selectAllState) {// 전체해제
                    state.selectedApplicant = new Array();
                } 
                else { // 전체선택
                    state.selectedApplicant = state.currentPageData;
                }

                state.selectAllState = !state.selectAllState;
            }
        }

        onMounted(() => {

            methods.getNotice();
        })

        return { state, methods, eventHandler, applicantMng, RECRC0310D };
    }
}

</script>